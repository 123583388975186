import { useState } from 'react';
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import { ReactElement } from 'react';
import { MapComponent } from '../../components/Map';
import * as S from './styles';
import { Marker } from '../../components/Marker';

export const Location: React.FC = () => {
  const [clicks, setClicks] = useState<google.maps.LatLng[]>([]);
  const onClick = (e: google.maps.MapMouseEvent) => {
    // avoid directly mutating state
    setClicks([...clicks, e.latLng!]);
  };

  const onIdle = (m: google.maps.Map) => {};
  // [END maps_react_map_component_app_state]

  const render = (status: Status): ReactElement => {
    switch (status) {
      case Status.LOADING:
        return <>"Loading...."</>;
      case Status.FAILURE:
        return <>'Failed'</>;
      case Status.SUCCESS:
        return (
          <MapComponent
            center={{
              lat: 50.57358,
              lng: -4.093266,
            }}
            onClick={onClick}
            onIdle={onIdle}
            zoom={11}
            style={{ flexGrow: '1', height: '100%' }}
          >
            <Marker
              label="Bearslake Inn"
              position={{
                lat: 50.68098,
                lng: -4.08447,
              }}
            ></Marker>
            <Marker
              label="Burrator Inn"
              position={{
                lat: 50.501581456077574,
                lng: -4.064325344172275,
              }}
            ></Marker>

            <Marker
              label="Peter Tavy Inn"
              position={{
                lat: 50.58116,
                lng: -4.1028,
              }}
            />
            <Marker
              label="The Royal Oak"
              position={{
                lat: 50.48663296657617,
                lng: -4.058491369796324,
              }}
            />
            <Marker
              label="The Skylark Inn"
              position={{
                lat: 50.47101,
                lng: -4.08524,
              }}
            />
            <Marker
              label="The Walkhampton Inn"
              position={{
                lat: 50.50902,
                lng: -4.06996,
              }}
            />
          </MapComponent>
        );
    }
  };
  return (
    <S.Wrapper>
      <Wrapper
        apiKey={'AIzaSyD0v-h2o8d0GSUyIm2gWZsJsmP4LUNRVS8'}
        render={render}
      />
    </S.Wrapper>
  );
};
