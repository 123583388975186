import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.blackColor};
  display: flex;
  flex-direction: column;
  padding: 2em 2em 4em 2em;
  @media (max-width: 800px) {
    justify-content: center;
    padding-bottom: 2em;
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  & h1 {
    color: ${({ theme }) => theme.orangeColor};
  }
  @media (max-width: 800px) {
    justify-content: center;
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const LeftPanel = styled.div`
  padding: 2em;
  img {
    width: 340px;
    @media (max-width: 800px) {
      width: 100%;
    }
  }
`;

export const RightPanel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
`;
