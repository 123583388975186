import styled from 'styled-components';
import { Button as AntButton } from 'antd';

export const Button = styled(AntButton)`
  &:hover,
  :active,
  :focus,
  :focus-within,
  :focus-visible,
  :target {
    border-color: ${({ theme }) => theme.orangeColor};
    color: ${({ theme }) => theme.orangeColor};
  }
`;
