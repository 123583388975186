import styled from 'styled-components';
import { Carousel as AntCarousel } from 'antd';

export const Image = styled.img`
  height: 100%;
  width: 100%;
  @media (max-width: 800px) {
    height: 400px;
    margin-left: -100px;
    width: 200%;
  }
`;

export const Item = styled.div`
  overflow: hidden;
`;

export const HeaderLeft = styled.div`
  position: absolute;
  top: 35%;
  left: 200px;
  @media (max-width: 800px) {
    left: 30px;
  }
`;

export const DescriptionLeft = styled.div`
  position: absolute;
  top: 50%;
  left: 200px;
  width: 590px;
  @media (max-width: 800px) {
    display: none;
  }
`;

export const HeaderRight = styled.div`
  position: absolute;
  top: 35%;
  right: 200px;
  @media (max-width: 800px) {
    right: 30px;
  }
`;

export const DescriptionRight = styled.div`
  position: absolute;
  top: 50%;
  right: 200px;
  width: 590px;
  @media (max-width: 800px) {
    display: none;
  }
`;

export const TextWrapper = styled.div`
  font-size: 48px;
  #border: 2px solid white;
  padding: 0.2em 0.3em 0.2em 0.3em;
  @media (max-width: 800px) {
    font-size: 18px;
  }
`;

export const HeaderOrange = styled.div`
  color: ${({ theme }) => theme.whiteColor};
  background-color: ${({ theme }) => theme.orangeColor};
  width: fit-content;
  padding: 0.1em 0.1em 0.1em 0.1em;
  transition: opacity 10s ease;
`;

export const HeaderGray = styled.div`
  color: ${({ theme }) => theme.whiteColor};
  background-color: ${({ theme }) => theme.grayColor};
  width: fit-content;
  padding: 0.1em 0.1em 0.1em 0.1em;
`;

export const Description = styled.div`
  position: absolute;
  top: 55%;
  width: 13%;
  padding-left: 100px;
  font-size: 16px;
  word-wrap: break-word;
  color: ${({ theme }) => theme.whiteColor};
`;

export const CarouselWrapper = styled.div``;
export const Carousel = styled(AntCarousel)`
  > .slick-dots li button {
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background: transparent;
    border: 2px solid white;
    opacity: 1;
  }
  > .slick-dots li.slick-active {
    width: unset;
  }
  > .slick-dots li.slick-active button {
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background: white;
  }
`;
