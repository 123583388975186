import React from 'react';
import * as S from './styles';

export const Footer: React.FC = React.memo(() => {
  return (
    <S.Wrapper>
      <S.Pillar>
        <S.Title>OUR LOCATION</S.Title>
        <S.Row>The Old Root House</S.Row>
        <S.Row> Harragrove Farm</S.Row>
        <S.Row> Peter Tavy</S.Row>
        <S.Row> Tavistock</S.Row>
        <S.Row> PL19 9JR </S.Row>
        <S.Blank />
        <S.Row>Telephone: 07564 869198</S.Row>
        <S.Blank />
        <S.Row>
          Email:&nbsp;
          <a href="mailto:tavistockbrewery@gmail.com">
            tavistockbrewery@gmail.com
          </a>
        </S.Row>
      </S.Pillar>
      <S.Pillar>
        <a
          href="https://plymouth.camra.org.uk/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="/images/We-Support-CAMRA-logo-2019.png"
            style={{ height: '200px' }}
            alt=""
          />
        </a>
      </S.Pillar>
    </S.Wrapper>
  );
});
