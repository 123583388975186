import { Card } from 'antd';
import React from 'react';
import * as S from './styles';
import { CSSProperties } from 'styled-components';

export const BeerPanel: React.FC = React.memo(() => {
  const style: CSSProperties = {
    boxShadow: '5px 8px 24px 5px rgba(208, 216, 243, 0.6)',
  };
  return (
    <S.Wrapper data-testid="beers-wrapper">
      <S.Title data-testid="beers-title">
        <h1>OUR BEERS</h1>
      </S.Title>
      <S.Row data-testid="beers-row">
        <S.Pillar data-testid="beers-pillar_01">
          <Card
            bordered
            cover={<img alt="..." src="./images/master_ale.png" />}
            className="beer-card"
            style={style}
          >
            <S.CardBodyWrapper>
              <S.CardBody>
                <S.CardHeader>Master Ale</S.CardHeader>
                <S.CardSubHeader> 4.6% ABV</S.CardSubHeader>
                <S.CardDescription>
                  A blend of pale and chocolate malts gives this Porter-type ale
                  a wonderful ruby colour which hints at a richness of flavour.
                  It doesn’t disappoint!
                </S.CardDescription>
              </S.CardBody>
            </S.CardBodyWrapper>
          </Card>
        </S.Pillar>
        <S.Pillar data-testid="beers-pillar_02">
          <Card
            bordered
            cover={<img alt="..." src="./images/golden_ale.png" />}
            className="beer-card"
            style={style}
          >
            <S.CardBodyWrapper>
              <S.CardBody>
                <S.CardHeader>Golden Ale</S.CardHeader>
                <S.CardSubHeader>4.3% ABV</S.CardSubHeader>
                <S.CardDescription>
                  A lighter ale in colour but one that boasts a very satisfying
                  taste. The blend of malts and hops conjures images of summer
                  evenings but this one can be enjoyed all year round!
                </S.CardDescription>
              </S.CardBody>
            </S.CardBodyWrapper>
          </Card>
        </S.Pillar>
        <S.Pillar data-testid="beers-pillar_03">
          <Card
            bordered
            cover={<img alt="..." src="./images/english_ale.png" />}
            className="beer-card"
            style={style}
          >
            <S.CardBodyWrapper>
              <S.CardBody>
                <S.CardHeader>English Ale</S.CardHeader>
                <S.CardSubHeader>4.5% ABV</S.CardSubHeader>
                <S.CardDescription>
                  A beer in the traditional ale mode but with a modern twist,
                  thanks to a clever use of hops. This excellent ale leaves you
                  satisfied with a taste that lingers
                </S.CardDescription>
              </S.CardBody>
            </S.CardBodyWrapper>
          </Card>
        </S.Pillar>
      </S.Row>
    </S.Wrapper>
  );
});
