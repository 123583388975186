import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { Button } from '../../components/Button';
import { BeerCarousel } from './BeerCarousel';
import { BeerPanel } from './BeerPanel';
import { InformationPanel } from './InformationPanel';
import * as S from './styles';
import './index.scss';
import { Cookies } from 'react-cookie';
import { JourneyPanel } from './JourneyPanel';
import { ShopPanel } from './ShopPanel';

const key = 'AGE_GATE_ACCEPTANCE';
const cookies = new Cookies();

export const Landing: React.FC = () => {
  const cookie = cookies.get(key);
  const [visible, setVisible] = useState<boolean>(!cookie);

  useEffect(() => {
    if (!visible) {
      cookies.set(key, true, {
        maxAge: 3600,
      });
    }
  }, [visible]);

  return (
    <div>
      <BeerCarousel />
	  <ShopPanel />
      <BeerPanel />
      <JourneyPanel />
      <Modal
        visible={visible}
        footer={null}
        closable={false}
        centered
        maskStyle={{ backgroundColor: 'rgba(0,0,0,.75)' }}
      >
        <S.AgeGate>
          <p>
            <img src="./images/logo.svg" alt="Tavistock Brewery" width="78px" />
          </p>
          <h1>Are you over 18?</h1>
          <h3>
            By entering our site you agree that you are of legal drinking age
          </h3>
          <Button onClick={() => setVisible(false)}>ENTER</Button>
        </S.AgeGate>
      </Modal>
    </div>
  );
};
