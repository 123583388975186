import { Card } from 'antd';
import { memo } from 'react';
import * as S from './styles';

export const JourneyPanel: React.FC = memo(() => {
  return (
    <S.Wrapper data-testid="journey_wrapper">
      <S.Title>
        <h1>Our Journey</h1>
      </S.Title>
      <S.Row data-testid="journey_row_01">
        <S.Column>
          <p>
            By 2017 the partners, both lovers of beer and, dare we say,
            connoisseurs of ale, had identified a certain gap in the market.
            This gap, confirmed by local publicans, involved the lack of locally
            produced beer in the Tavistock area.
          </p>
          <p>
            In November 2017, the partners embarked on their brewing adventure
            by attending an intensive brewing course in Manchester hosted by
            Dave Porter, the doyen of all things brewery. Having successfully
            completed the course and coming away having built on their previous
            experience of brewing beer, they returned to Devon and set about
            renovating a farm building previously used as an old dairy. The roof
            was replaced, the external walls repointed, and new windows and
            doors were fitted. Inside, a complete transformation took place. A
            brewing floor was formed, an administration centre constructed, and
            a hospitality area established for potential publican customers.
            (Please note: Publicans, please give us some warning of your very
            welcome visit so we are not knee-deep in hops when you arrive!)
          </p>
          <p>Following this renovation…… Covid!</p>
        </S.Column>
        <S.Column>
          <S.CardWrapper>
            <Card
              style={{ width: 400 }}
              cover={<img alt="example" src="./images/our_journey_2.jpg" />}
            ></Card>
          </S.CardWrapper>
        </S.Column>
      </S.Row>
      <S.Row data-testid="journey_row_01">
        <S.Column>
          <S.CardWrapper>
            <Card
              style={{ width: 400 }}
              cover={<img alt="example" src="./images/our_journey.jpg" />}
            ></Card>
          </S.CardWrapper>
        </S.Column>
        <S.Column>
          <p>
            The pandemic caused huge disruption in the ‘hospitality industry’
            which obviously included brewers, so our planned entry into the
            brewing industry was delayed.
          </p>
          <p>
            However, these delays proved beneficial because they provided time
            for the brewers to hone their art. Brew upon brew followed, each
            analysed with a critical eye, and improvements were made until
            excellence was achieved. The end result is that we believe we have
            created three outstanding ales, each one different from the others,
            but every one of the very highest quality.
          </p>
          <p>
            Tavistock Brewery continues to flourish, attending local Beer
            Festivals with shining reviews from tasters and already located in a
            growing number of public houses and restaurants in the local area.
            We aim to supply the locality with what we believe to be a unique
            and tasty range of beers and form a lasting bond with local fellow
            beer lovers. If you are a local publican and wish to sample or learn
            more about Tavistock Brewery and our beers, please do get in touch
            and arrange a visit.
          </p>
        </S.Column>
      </S.Row>
    </S.Wrapper>
  );
});
