import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 800px) {
    flex-direction: column;
  }
  padding-top: 2em;
  padding-bottom: 2em;
  justify-content: center;
  background-color: ${({ theme }) => theme.blackColor};
`;

export const Pillar = styled.div`
  flex-basis: 25%;
  display: flex;
  color: ${({ theme }) => theme.grayColor};
  flex-direction: column;
  @media (max-width: 800px) {
    width: 100%;
    text-align: center;
    padding-bottom: 20px;
  }
`;
export const Col = styled.div`
  display: flex;
  color: ${({ theme }) => theme.grayColor};
  flex-direction: column;
  flex: 1;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 800px) {
    width: 100%;
    padding-left: 25%;
    text-align: center;
  }
  & a:link,
  a:visited,
  a:hover,
  a:active {
    color: ${({ theme }) => theme.orangeColor};
    text-decoration: none;
  }
`;

export const Blank = styled.div`
  margin-bottom: 1em;
  @media (max-width: 800px) {
    margin-bottom: 0.2em;
  }
`;

export const Title = styled.div`
  @media (max-width: 800px) {
    width: 100%;
    padding-left: 25%;
    text-align: center;
  }
  display: flex;
  flex-direction: row;
  color: #ffffff;
  margin-bottom: 1em;
  font-weight: 400 !important;
`;
