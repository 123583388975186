import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.grayColor};
  display: flex;
  flex-direction: column;
  padding: 2em 2em 4em 2em;
  @media (max-width: 800px) {
    justify-content: center;
    padding-bottom: 2em;
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  & h1 {
    color: ${({ theme }) => theme.orangeColor};
  }
  @media (max-width: 800px) {
    justify-content: center;
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const CardBodyWrapper = styled.div.attrs({
  className: 'ant-card-meta',
})``;

export const CardBody = styled.div.attrs({
  className: 'ant-card-meta-detail',
})``;
export const CardHeader = styled.div.attrs({
  className: 'ant-card-meta-title',
})``;
export const CardSubHeader = styled.div.attrs({
  className: 'ant-card-meta-description',
})``;
export const CardDescription = styled.div.attrs({
  className: 'ant-card-meta-description',
})``;

export const Pillar = styled.div`
  flex-basis: 20%;
  display: flex;
  color: ${({ theme }) => theme.grayColor};
  flex-direction: column;
  margin-right: 0.5em;
  & .ant-card {
    width: 240px;
    height: 100%;
  }
  @media (max-width: 800px) {
    padding-bottom: 2em;
    justify-content: center;
    align-items: center;
  }
`;
