import { useState, useEffect, PropsWithChildren } from 'react';

export type Props = {
  map: google.maps.Map | undefined;
  marker: google.maps.Marker | undefined;
  onClose: () => void;
};

export const InfoWindow: React.FC<PropsWithChildren<Props>> = (
  { map, marker, children, onClose }: PropsWithChildren<Props>,
  ref,
) => {
  const [infoWindow, setInfoWindow] = useState<google.maps.InfoWindow>();

  useEffect(() => {
    if (infoWindow && map && marker) {
      infoWindow?.open(map, marker);
    }
  }, [infoWindow, map, marker]);

  useEffect(() => {
    if (!infoWindow) {
      const iw = new google.maps.InfoWindow({});
      google.maps.event.addListener(iw, 'closeclick', () => onClose());
      setInfoWindow(iw);
    }
  }, [infoWindow, onClose]);

  return null;
};
