import { useEffect, useState } from 'react';

export interface MarkerProps extends google.maps.MarkerOptions {
  onClick?: (marker: google.maps.Marker) => void;
}

export const Marker: React.FC<MarkerProps> = (options) => {
  const [marker, setMarker] = useState<google.maps.Marker>();

  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker) {
      marker.setOptions(options);
      if (options.onClick)
        google.maps.event.addListener(marker, 'click', () => {
          if (options.onClick) options.onClick(marker);
        });
    }
  }, [marker, options]);

  return null;
};
