import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.whiteColor};
  display: flex;
  flex-direction: column;
  color: black;
`;

export const Title = styled.div`
  display: flex;
  padding: 2em;
  flex-direction: row;
  & h1 {
    color: ${({ theme }) => theme.grayColor};
  }
  @media (max-width: 800px) {
    justify-content: center;
  }
`;
export const Row = styled.div`
  padding: 2em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const CardWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  & .ant-card-bordered {
    border: 1px solid rgba(0, 0, 0, 0.06);
  }
  & .ant-card {
    box-shadow: 0 8px 8px -4px black;
    box-sizing: border-box;
    margin: 0;
    padding: 10px;
    color: #000000d9;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    position: relative;
    background: #fff;
    border-radius: 2px;
  }
`;
export const Column = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 450px;
  @media (max-width: 800px) {
    width: 100%;
  }
`;
