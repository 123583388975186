import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  height: 125px;
  background-color: ${({ theme }) => theme.blackColor};
  border-bottom: 1px solid ${({ theme }) => theme.grayColor};
  align-items: center;
  @media (max-width: 800px) {
    justify-content: center;
  }
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 66px;
  padding-left: 2em;
  height: 100%;
  @media (max-width: 800px) {
    padding-left: 0em;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 66px;
  padding-left: 2em;
  height: 100%;
`;

export const RightPanel = styled.div`
  flex: 1;
  height: 100%;
  display: flex;

  justify-content: right;
  flex-direction: row;
`;

export const Contact = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 2em;
`;

export const Mobile = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const Email = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  & a:link,
  a:visited,
  a:hover,
  a:active {
    color: ${({ theme }) => theme.orangeColor};
    text-decoration: none;
  }
`;
