import { FC } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Hero } from './components/Hero';
import { Landing } from './containers/Landing';
import { Location } from './containers/Location';
import theme from './styles/default';
import './App.scss';
import 'antd/dist/antd.dark.min.css';
import { Footer } from './components/Footer';
import { Community } from './containers/Community';

const App: FC = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Hero />
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/location" element={<Location />} />
            <Route path="/community" element={<Community />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
};

export default App;
