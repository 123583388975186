import { Button, Card } from 'antd';
import React from 'react';
import * as S from './styles';
import { CSSProperties } from 'styled-components';
import { Link } from 'react-router-dom';

export const ShopPanel: React.FC = React.memo(() => {
  const style: CSSProperties = {
    boxShadow: '5px 8px 24px 5px rgba(208, 216, 243, 0.6)',
  };

  return (
    <S.Wrapper data-testid="shop-wrapper">
      <S.Title data-testid="shop-title">
        <a href="https://0b565e-fb.myshopify.com">
          <h1>Buy Online</h1>
        </a>
      </S.Title>
      <S.Row data-testid="shop-row">
        <S.LeftPanel data-testid="shop-left_pillar_01">
          <img src="./images/3_bottles_2.jpeg" />
        </S.LeftPanel>
        <S.RightPanel data-testid="beers-right_pillar_02">
          <p>
            Our delicious beer is now avaliable to buy online for home delivery.
          </p>
          <p>
            Visit our store where we offer a range of options as well as gift
            sets.
          </p>
          <p>
            <Button
              onClick={() => window.open('https://0b565e-fb.myshopify.com')}
            >
              Shop Now
            </Button>
          </p>
        </S.RightPanel>
      </S.Row>
    </S.Wrapper>
  );
});
