import React from 'react';
import * as S from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faShopify } from '@fortawesome/free-brands-svg-icons';
import { isMobile } from 'react-device-detect';

export const Hero: React.FC = React.memo(() => {
  return (
    <S.Wrapper data-testid="hero-wrapper">
      <S.Logo data-testid="hero-logo">
        <img src="./images/logo.svg" alt="Tavistock Brewery" width="78px" />
      </S.Logo>
      <S.Title data-testid="hero-title">
        <img src="./images/text.svg" alt="Tavistock Brewery" height="48px" />
      </S.Title>
      {isMobile ? null : (
        <S.RightPanel>
          <S.Contact data-testid="hero-contact">
            <S.Mobile data-testid="hero-mobile">
              <FontAwesomeIcon icon={faPhone} style={{ marginRight: '1em' }} />
              07564 869198
            </S.Mobile>
            <S.Email data-testid="hero-email">
              <a href="mailto:tavistockbrewery@gmail.com">
                <FontAwesomeIcon
                  icon={faEnvelope}
                  style={{ marginRight: '1em' }}
                />
                tavistockbrewery@gmail.com
              </a>
            </S.Email>
            <S.Mobile>
              <a href="https://www.facebook.com/people/Tavistock-Brewery/61553304061852/">
                <FontAwesomeIcon
                  icon={faFacebook}
                  style={{ marginRight: '1em' }}
                />
                facebook.com
              </a>
            </S.Mobile>
            <S.Email>
              <a href="https://0b565e-fb.myshopify.com">
                <FontAwesomeIcon
                  icon={faShopify}
                  style={{ marginRight: '1em' }}
                />
                shop
              </a>
            </S.Email>
          </S.Contact>
        </S.RightPanel>
      )}
    </S.Wrapper>
  );
});
