const theme = {
  whiteColor: '#ffffff',
  blackColor: '#1b1b1f',
  grayColor: '#5c5b60ff',
  orangeColor: '#ee8a2aff',
  heading1: {
    fontFamily: '',
    fontStyle: '',
    fontWeight: '300',
    fontSize: '32px !important',
    lineHeight: '40px',
  },
  heading2: {
    fontFamily: '',
    fontStyle: '',
    fontWeight: '300',
    fontSize: '28px !important',
    lineHeight: '36px',
  },
  heading3: {
    fontFamily: '',
    fontStyle: '',
    fontWeight: '400',
    fontSize: '20px !important',
    lineHeight: '26px',
  },
  heading4: {
    fontFamily: '',
    fontStyle: '',
    fontWeight: '300',
    fontSize: '14px !important',
    lineHeight: '18px',
  },
  labelSmall: {
    fontFamily: '',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '10px',
    lineHeight: '12px',
  },
  labelItalic: {
    fontFamily: '',
    fontStyle: 'italic',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '14px',
  },
  labelBold: {
    fontFamily: '',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '12px',
    lineHeight: '14px',
  },
};

export default theme;
