import styled from 'styled-components';

export const AgeGate = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &h3 {
    margin-bottom: 0.5em;
  }
  &h2 {
    margin-bottom: 0.3em;
  }
`;
